/* montserrat-100normal - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src:
    local('Montserrat Thin '),
    local('Montserrat-Thin'),
    url('./files/montserrat-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/montserrat-latin-100.woff') format('woff'); /* Modern Browsers */
}

/* montserrat-100italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-display: swap;
  font-weight: 100;
  src:
    local('Montserrat Thin italic'),
    local('Montserrat-Thinitalic'),
    url('./files/montserrat-latin-100italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/montserrat-latin-100italic.woff') format('woff'); /* Modern Browsers */
}

/* montserrat-200normal - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src:
    local('Montserrat Extra Light '),
    local('Montserrat-Extra Light'),
    url('./files/montserrat-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/montserrat-latin-200.woff') format('woff'); /* Modern Browsers */
}

/* montserrat-200italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-display: swap;
  font-weight: 200;
  src:
    local('Montserrat Extra Light italic'),
    local('Montserrat-Extra Lightitalic'),
    url('./files/montserrat-latin-200italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/montserrat-latin-200italic.woff') format('woff'); /* Modern Browsers */
}

/* montserrat-300normal - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Montserrat Light '),
    local('Montserrat-Light'),
    url('./files/montserrat-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/montserrat-latin-300.woff') format('woff'); /* Modern Browsers */
}

/* montserrat-300italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src:
    local('Montserrat Light italic'),
    local('Montserrat-Lightitalic'),
    url('./files/montserrat-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/montserrat-latin-300italic.woff') format('woff'); /* Modern Browsers */
}

/* montserrat-400normal - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Montserrat Regular '),
    local('Montserrat-Regular'),
    url('./files/montserrat-latin-400.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/montserrat-latin-400.woff') format('woff'); /* Modern Browsers */
}

/* montserrat-400italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('Montserrat Regular italic'),
    local('Montserrat-Regularitalic'),
    url('./files/montserrat-latin-400italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/montserrat-latin-400italic.woff') format('woff'); /* Modern Browsers */
}

/* montserrat-500normal - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src:
    local('Montserrat Medium '),
    local('Montserrat-Medium'),
    url('./files/montserrat-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/montserrat-latin-500.woff') format('woff'); /* Modern Browsers */
}

/* montserrat-500italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src:
    local('Montserrat Medium italic'),
    local('Montserrat-Mediumitalic'),
    url('./files/montserrat-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/montserrat-latin-500italic.woff') format('woff'); /* Modern Browsers */
}

/* montserrat-600normal - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src:
    local('Montserrat SemiBold '),
    local('Montserrat-SemiBold'),
    url('./files/montserrat-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/montserrat-latin-600.woff') format('woff'); /* Modern Browsers */
}

/* montserrat-600italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src:
    local('Montserrat SemiBold italic'),
    local('Montserrat-SemiBolditalic'),
    url('./files/montserrat-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/montserrat-latin-600italic.woff') format('woff'); /* Modern Browsers */
}

/* montserrat-700normal - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Montserrat Bold '),
    local('Montserrat-Bold'),
    url('./files/montserrat-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/montserrat-latin-700.woff') format('woff'); /* Modern Browsers */
}

/* montserrat-700italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('Montserrat Bold italic'),
    local('Montserrat-Bolditalic'),
    url('./files/montserrat-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/montserrat-latin-700italic.woff') format('woff'); /* Modern Browsers */
}

/* montserrat-800normal - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src:
    local('Montserrat ExtraBold '),
    local('Montserrat-ExtraBold'),
    url('./files/montserrat-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/montserrat-latin-800.woff') format('woff'); /* Modern Browsers */
}

/* montserrat-800italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-display: swap;
  font-weight: 800;
  src:
    local('Montserrat ExtraBold italic'),
    local('Montserrat-ExtraBolditalic'),
    url('./files/montserrat-latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/montserrat-latin-800italic.woff') format('woff'); /* Modern Browsers */
}

/* montserrat-900normal - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src:
    local('Montserrat Black '),
    local('Montserrat-Black'),
    url('./files/montserrat-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/montserrat-latin-900.woff') format('woff'); /* Modern Browsers */
}

/* montserrat-900italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src:
    local('Montserrat Black italic'),
    local('Montserrat-Blackitalic'),
    url('./files/montserrat-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/montserrat-latin-900italic.woff') format('woff'); /* Modern Browsers */
}

